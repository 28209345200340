import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ExpensesComponent } from './components/expenses.component';
import { ExpensesRoutingModule } from './expenses-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { AddExpensesComponent } from './add-expenses/add-expenses.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ExpensesComponent,
    AddExpensesComponent,
  ],
  imports: [
    CommonModule,
    ExpensesRoutingModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule,
    NgSelectModule
  ],
  providers: [],
})
export class ExpensesModule { }
