import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class IntegrationService {

    constructor(private http: HttpClient) { }

    private integration = environment.integrationService + '/v1';

    uploadFile(payload: any): Observable<any> {
        return this.http
            .post<any>(this.integration + `/file/upload-file`, payload)
            .pipe(catchError((err) => throwError(err)));
    }

    getActivitiesByCriteria(payload: any, page:any, pageSize:any): Observable<any> {
        return this.http
            .post<any>(this.integration + `/activity-tracker/all?page=${page}&pageSize=${pageSize}`, payload)
            .pipe(catchError((err) => throwError(err)));
    }

    // Notification

    getNotification(shopId: any, page: any, pageSize: any): Observable<any> {
        return this.http.get<any>(this.integration + `/notification/shop/${shopId}?page=${page}&pageSize=${pageSize}`)
            .pipe(catchError((err) => throwError(err)));
    }

    updateNotificationStatus(payload: any): Observable<any> {
        return this.http.put<any>(this.integration + `/notification/mark-read`, payload)
            .pipe(catchError((err) => throwError(err)));
    }

    // announcements
    getAnnouncementsByCriteria(payload: any, page:any, pageSize:any): Observable<any> {
        return this.http.post<any>(this.integration + `/announcement/all?page=${page}&pageSize=${pageSize}`, payload)
            .pipe(catchError((err) => throwError(err)));
    }

}