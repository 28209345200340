import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpensesComponent } from './components/expenses.component';
import { FormsModule } from '@angular/forms';
import { AddExpensesComponent } from './add-expenses/add-expenses.component';
const routes: Routes = [
  {
    path: '',
    component: ExpensesComponent
  },
  {
    path:'add-expenses',
    component:AddExpensesComponent
  },
  {
    path: 'edit-expenses',
    component: AddExpensesComponent
},
];

@NgModule({
  imports: [RouterModule.forChild(routes),
  FormsModule],
  exports: [RouterModule]
})
export class ExpensesRoutingModule { }
