import { Component } from '@angular/core';
import { SidebarItems } from 'src/app/constants/sidebarItems.constant';
import { CommonCommunicationService } from 'src/app/core/services/commonCommunication.service';
import { Location} from '@angular/common';
import { Router } from '@angular/router';
import { ExpensesComponent } from 'src/app/pages/expenses/components/expenses.component';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  lastPageUrl:string

  constructor(private ccs: CommonCommunicationService,
    private location:Location,
    private router:Router) { 
    this.lastPageUrl = this.getLastPageUrl();
      console.log("this.lastPageUrl",this.lastPageUrl);
  }

  sidebarItems: any = []
  selectedItem: any = {}
  showSubModule: boolean = false
  rolePermission: any = {}
  catalougeOpenFlag:boolean = false; 
  salesOpenFlag:boolean = false; 

  ngOnInit() {
    this.ccs.getRolePermission().subscribe((res: any) => {
      if (res) {
        let items: any = SidebarItems;
        this.rolePermission = res;
        const expenses = {Expenses : { "Can View": true,"Add & Edit Expenses":true,"Delete Expense":true}}
        this.rolePermission = { ...this.rolePermission, ...expenses, }
        const pricing = {Pricing : { "Can View": true}}
        this.rolePermission = { ...this.rolePermission, ...pricing, }
        this.sidebarItems = [];
        for (let i = 0; i < items.length; i++) {
          if (this.rolePermission[items[i].title] && this.rolePermission[items[i].title]['Can View']) {
            let itemData = { ...items[i] }
            if (items[i].submodule) {
              itemData = { ...items[i], module: [] }
              for (let j = 0; j < items[i].module.length; j++) {
                if (this.rolePermission[items[i].module[j].title] && this.rolePermission[items[i].module[j].title]['Can View']) {
                  itemData.module.push(items[i].module[j])
                }
              }
            }
            this.sidebarItems.push(itemData)
          }
        }
      }
    })
  }
  getLastPageUrl(): string {
    const pathArray = this.location.path().split('/');
    console.log("pathArray",pathArray);

    if((pathArray[2] == 'service') || (pathArray[2] == 'voucher') || (pathArray[2] ==  'membership') 
    || (pathArray[2] ==  'supplier') || (pathArray[2] ==  'product')){
      this.catalougeOpenFlag = true;
      this.salesOpenFlag = false;
      console.log("1");
      
      return pathArray[2] ;
    }else if((pathArray[2] == 'list') || (pathArray[2] ==  'new-sale') || (pathArray[2] ==  'transaction') 
    || (pathArray[2] ==  'voucher-sold') || (pathArray[2] ==  'membership-sold')){
      this.catalougeOpenFlag = false;
      this.salesOpenFlag = true;
      console.log("2");
      return pathArray[2] ;
    }else if(pathArray.length == 1 && pathArray[0] == ''){
      this.catalougeOpenFlag = false;
      this.salesOpenFlag = false;
      console.log("3");
      return pathArray[0];
    }
    else{
      this.catalougeOpenFlag = false;
      this.salesOpenFlag = false;
      console.log("4");
      return pathArray[1];
    }
  }


  setActiveMenuItem(): void {
    setTimeout(()=>{     
      this.lastPageUrl = this.getLastPageUrl();
      console.log("this.lastPageUrlthis.lastPageUrl",this.lastPageUrl);
  }, 10);
  }
  hoverItem(data: any) {
    if (data.module) {
      this.showSubModule = true
      this.selectedItem = data
    } else {
      this.showSubModule = false
    }
  }

}
