export const environment = {
  production: true,
  //ownerId:"64b274017bef56e0e818485f",
  //devServer: 'http://15.206.88.40/',
  //offerService: 'http://15.206.88.40/offer-service/api',
  //productService: 'http://15.206.88.40/product-service/api',
  //orderService: 'http://15.206.88.40/order-service/api',
  //customerService: 'http://15.206.88.40/customer-service/api',
  //userService: 'http://15.206.88.40/user-service/api',
  //imageService: 'http://15.206.88.40/image-service/api',
  //integrationService: 'http://15.206.88.40/integrations-service/api',
  //inventoryService: 'http://15.206.88.40/inventory-service/api',
  //reviewService:'http://15.206.88.40/review-service/api',
  //zalonUserService: 'http://15.206.88.40/zalon/user-service/api',
  //previewShop:"https://booking.zalon.in",
   ownerId:"64b65b9bea6b26fb8c3eb958",
   devServer: 'https://pos-api.myzalon.com/pos/',
   offerService: 'https://pos-api.myzalon.com/pos/offer-service/api',
   productService: 'https://pos-api.myzalon.com/pos/product-service/api',
   orderService: 'https://pos-api.myzalon.com/pos/order-service/api',
   customerService: 'https://pos-api.myzalon.com/pos/customer-service/api',
   userService: 'https://pos-api.myzalon.com/pos/user-service/api',
   imageService: 'https://pos-api.myzalon.com/pos/image-service/api',
   integrationService: 'https://pos-api.myzalon.com/pos/integrations-service/api',
   inventoryService: 'https://pos-api.myzalon.com/pos/inventory-service/api',
   reviewService:'https://pos-api.myzalon.com/pos/review-service/api',
   zalonUserService: 'https://api.myzalon.com/user-service/api',
   previewShop:"https://booking.zalon.in",
   razerpayKey: 'rzp_test_0hFCXvXjVBJbdL'
};
