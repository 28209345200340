import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class ProductService {
  

  constructor(private http: HttpClient) { }

  private productService = environment.productService + '/v1'

  //services CRUD
  createService(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + "/service", payload)
      .pipe(catchError((err) => throwError(err)));
  }
  updateService(payload: any): Observable<any> {
    return this.http
      .put<any>(this.productService + `/service`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  getServiceList(payload: any, queryObj: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/service/get?page=${queryObj.page}&pageSize=${queryObj.pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  deleteService(id: any): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/service/${id}`)
      .pipe(catchError((err) => throwError(err)));
  }
  getServiceById(id: any): Observable<any> {
    return this.http
      .get<any>(this.productService + `/service/get/${id}`)
      .pipe(catchError((err) => throwError(err)));
  }

  exportServices(payload:any): Observable<any> {
    return this.http
      .post<any>(this.productService + "/service/export", payload)
      .pipe(catchError((err) => throwError(err)));
  }

  //collection crud

  createCollection(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/collection/add-collection`, payload)
      .pipe(catchError((err) => throwError(err)))
  }

  updateCollection(payload: any): Observable<any> {
    return this.http
      .put<any>(this.productService + `/collection/update-collection`, payload)
      .pipe(catchError((err) => throwError(err)))
  }

  deleteCollection(id: any): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/collection/delete-collection/${id}`)
      .pipe(catchError((err) => throwError(err)))
  }

  getCollections(payload: any, queryObj: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/collection/fetch-collection?page=${queryObj.page}&pageSize=${queryObj.pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  getCollectionById(reqObj: any): Observable<any> {
    return this.http
      .get<any>(this.productService + '/collection' + '/fetch-collection/' + reqObj.collectionId)
      .pipe(catchError((err) => throwError(err)));
  }

  checkCollectionName(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/collection/check-exists`, payload)
      .pipe(catchError((err) => throwError(err)));
  }


  //vendor crud

  createVendor(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/vendor/add-vendor`, payload)
      .pipe(catchError((err) => throwError(err)))
  }

  updateVendor(payload: any): Observable<any> {
    return this.http
      .patch<any>(this.productService + `/vendor/update-vendor`, payload)
      .pipe(catchError((err) => throwError(err)))
  }

  deleteVendor(id: any): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/vendor/delete-vendor/${id}`)
      .pipe(catchError((err) => throwError(err)))
  }

  getVendor(payload: any, queryObj: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/vendor/get-vendors?page=${queryObj.page}&pageSize=${queryObj.pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  //product crud

  getAllProductVariants(payload: any, page: number, pageSize: number): Observable<any> {
    return this.http
      .post<any>(this.productService + `/product-variant/fetch-product-variant?page=${page}&pageSize=${pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  updateProductVariant(payload: any): Observable<any> {
    return this.http
      .patch<any>(this.productService + `/product-variant/update-product-variant`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  getCollectionList(payload: any, page: number, pageSize: number): Observable<any> {
    return this.http
      .post<any>(this.productService + `/collection/fetch-collection?page=${page}&pageSize=${pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  getProductList(payload: any, page: number, pageSize: number): Observable<any> {
    return this.http
      .post<any>(this.productService + `/product/fetch-product?page=${page}&pageSize=${pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  updateProduct(payload: any): Observable<any> {
    return this.http
      .put<any>(this.productService + `/product/update-product`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  deleteProduct(productId: string): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/product/delete-product/${productId}`)
      .pipe(catchError((err) => throwError(err)));
  }

  getProductById(productId: string): Observable<any> {
    return this.http
      .get<any>(this.productService + `/product/fetch-product/${productId}`)
      .pipe(catchError((err) => throwError(err)));
  }
  createProduct(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + "/product/add-product", payload)
      .pipe(catchError((err) => throwError(err)));
  }

  checkProductName(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/product/check-exists`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  //taxes crud
  getAllTaxes(payload: any, page: number, pageSize: number): Observable<any> {
    return this.http
      .post<any>(
        this.productService + `/tax/get?page=${page}&pageSize=${pageSize}`,
        payload
      )
      .pipe(catchError((err) => throwError(err)));
  }

  createTax(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/tax`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  updateTax(payload: any): Observable<any> {
    return this.http
      .put<any>(this.productService + `/tax`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  deleteTax(id: any): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/tax/${id}`)
      .pipe(catchError((err) => throwError(err)));

  }

  public checkDuplicateTax(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + '/tax/check-exists', payload)
      .pipe(catchError((err) => throwError(err)));
  }

    // ************* Ratings *************** 

    public getRatingList(payload: any, page: any, pageSize: any): Observable<any> {
      return this.http.post<any>(this.productService + `/product/rating-list?page=${page}&pageSize=${pageSize}`, payload)
          .pipe(catchError((err) => throwError(err)));
  }
  
  public getRatingAnalytics(payload: any): Observable<any> {
      return this.http.post<any>(this.productService + `/product/rating-analytics`, payload)
          .pipe(catchError((err) => throwError(err)));
  }

  

  // catalogue builder

  public getTemplateCategories(payload: any): Observable<any> {
    return this.http.post<any>(this.productService + `/template-collection/fetch-collection`, payload)
        .pipe(catchError((err) => throwError(err)));
  }

  public getTemplateServices(payload:any): Observable<any> {
    return this.http.post<any>(this.productService + `/template-service/get`, payload)
        .pipe(catchError((err) => throwError(err)));
  }

  public addServicesToShop(payload:any): Observable<any> {
    return this.http.post<any>(this.productService + `/template-service/add-to-shop`, payload)
        .pipe(catchError((err) => throwError(err)));
  }
  public getAllExpenseTypes(payload: any, page: number, pageSize: number): Observable<any> {
    return this.http
      .post<any>(
        this.productService + `/expenseType/get-expenseType?page=${page}&pageSize=${pageSize}`,
        payload
      )
      .pipe(catchError((err) => throwError(err)));
  }
  public fetchAllExpenses(payload: any, page: number, pageSize: number): Observable<any>{
    return this.http
      .post<any>(
        this.productService + `/expense/get-expenseType?page=${page}&pageSize=${pageSize}`,
        payload
      )
      .pipe(catchError((err) => throwError(err)));
  }

  public createExpenseType(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + `/expenseType`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  public createExpenses(payload:any):Observable<any>{
    return this.http
      .post<any>(this.productService + `/expense`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  getAllExpenses(payload: any, queryObj: any): Observable<any> {
    const url = `${this.productService}/expense/get-expense`;
    const params = new URLSearchParams(queryObj).toString();
    return this.http.post<any>(`${url}?${params}`, payload)
      .pipe(catchError((err)=>throwError(err)));
  }
  getExpenseById(_id: string,payload:any): Observable<any> {
    return this.http.get<any>(this.productService +`/expense/get-expense/${_id}`);
  }
  public updateExpenseType(payload: any): Observable<any> {
    return this.http
      .put<any>(this.productService + `/expenseType/update-expenseType`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  public updateExpenses(payload: any): Observable<any> {
    return this.http
      .put<any>(this.productService + `/expense/update-expense`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

   deleteExpenseType(id: any): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/expenseType/${id}`)
      .pipe(catchError((err) => throwError(err)));

  }
  deleteExpenses(id: any): Observable<any> {
    return this.http
      .delete<any>(this.productService + `/expense/${id}`)
      .pipe(catchError((err) => throwError(err)));

  }

  public checkDuplicateExpense(payload: any): Observable<any> {
    return this.http
      .post<any>(this.productService + '/expenseType/check-exists', payload)
      .pipe(catchError((err) => throwError(err)));
  }
}