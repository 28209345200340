import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { CommonCommunicationService } from 'src/app/core/services/commonCommunication.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.scss']
})
export class AddExpensesComponent implements OnInit {
  activeShopId: any;
  pageSize: number = 10;
  page: number = 1;
  expenseList: Array<any> = [];
  teamMemberList: Array<any> = [];
  expenseTypes: string[] = [];
  paymentModes: string[] = ['Cash', 'Debit', 'Credit', 'UPI', 'NEFT', 'Cheque'];
  editMode: boolean = false;
  currentExpenseId: string;

  expenseObj = {
    _id: null,
    expenseType: '',
    amount: null,
    expenseBy: '',
    expenseNote: '',
    paymentMode: ''
  };

  constructor(
    private router: Router,
    private ccs: CommonCommunicationService,
    private productService: ProductService,
    private toastService: HotToastService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['_id']) {
        this.editMode = true;
        this.currentExpenseId = params['_id'];
        this.getExpenseById(this.currentExpenseId,this.expenseObj);
      }
    });
    
    this.ccs.getActiveShopId().subscribe(res => {
      if (res) {
        this.activeShopId = res;
        this.getAllExpenses();
        this.getTeamMember();
      }
    });
  }
  addNewExpenseType(term: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const payload = {
        expenseType: term,
        expenseTypeNote: '',  // You might want to add a field for this in your form
        shop_id: this.activeShopId,
      };

      this.productService.checkDuplicateExpense(payload).subscribe(
        (res) => {
          if (res.exists) {
            this.toastService.error(res.message);
            reject('Duplicate expense type');
          } else {
            this.productService.createExpenseType(payload).subscribe(
              () => {
                this.toastService.success('New expense type added');
                this.getAllExpenseTypes();  // Refresh the expense types list
                resolve(term);
              },
              (err) => {
                console.error(err);
                this.toastService.error('Failed to add new expense type');
                reject('Failed to add new expense type');
              }
            );
          }
        },
        (err) => {
          console.error(err);
          this.toastService.error('Failed to check for duplicate expense type');
          reject('Failed to check for duplicate expense type');
        }
      );
    });
  }

  getAllExpenseTypes() {
    const payload: any = {
      show_expense: true,
      shop_id: this.activeShopId,
    };

    this.productService.getAllExpenseTypes(payload, this.page, this.pageSize).subscribe((res: { data: any }) => {
      this.expenseList = [...res.data];
      this.extractExpenseTypes();
    });
  }
  getExpenseById(_id: string,payload:any) {
    console.log('Fetching expense with ID:', _id);
    this.productService.getExpenseById(_id,payload).subscribe(
        (res: any) => {
            this.expenseObj = {
                _id: res._id,
                expenseType: res.expenseType,
                amount: res.amount,
                expenseBy: res.expenseBy,
                paymentMode: res.paymentMode,
                expenseNote: res.expenseNote || ''
            };
            console.log("asdasda")
        },
        (error) => {
            console.error('Error fetching expense:', error);
            this.toastService.error('Failed to fetch expense details');
        }
    );
}


  
  getTeamMember() {
    let payload = {
      shop_id: this.activeShopId
    }

    this.userService.getTeamMember(payload, { page: this.page, pageSize: this.pageSize }).subscribe(res => {
      this.teamMemberList = res.data;
    });
  }

  getAllExpenses() {
    const payload: any = {
      show_expense: true,
      shop_id: this.activeShopId,
    };

    this.productService.getAllExpenseTypes(payload, this.page, this.pageSize).subscribe((res: { data: any }) => {
      this.expenseList = [...res.data];
      this.extractExpenseTypes();
    });
  }

  extractExpenseTypes() {
    this.expenseTypes = [...new Set(this.expenseList.map(expense => expense.expenseType))];
  }

  createExpenseInfo() {
    if (!this.validate()) {
      return;
    }
    const payload: any = {
      _id:this.expenseObj._id,
      expenseType: this.expenseObj.expenseType,
      amount: this.expenseObj.amount,
      expenseBy: this.expenseObj.expenseBy,
      expenseNote: this.expenseObj.expenseNote,
      paymentMode: this.expenseObj.paymentMode,
      show_expense: true,
      shop_id: this.activeShopId,
    };

    this.productService.createExpenses(payload).subscribe(() => {
      this.toastService.success("Expense Added Successfully");
      this.router.navigate(['/expenses']);
    }, err => {
      console.log(err);
      this.toastService.error("Failed to add expense");
    });
  }

  updateExpenses() {
    if (!this.validate()) {
        return;
    }
    const payload: any = {
        _id: this.currentExpenseId,
        expenseType: this.expenseObj.expenseType,
        amount: this.expenseObj.amount,
        expenseBy: this.expenseObj.expenseBy,
        expenseNote: this.expenseObj.expenseNote,
        paymentMode: this.expenseObj.paymentMode,
        shop_id: this.activeShopId,
    };

    this.productService.updateExpenses(payload).subscribe(
        (res) => {
            this.toastService.success("Expense Updated Successfully");
            this.router.navigate(['/expenses']);
        },
        (err) => {
            console.log(err);
            this.toastService.error("Failed to update expense");
        }
    );
}

  validate(): any {
    const regex = /^[a-zA-Z\s]+$/;
    const regexNo = /^[0-9]+$/;

    if (!this.expenseObj.expenseType) {
      this.toastService.error("Please Select Expense Type")
      return false
    }

    if (this.expenseObj.amount && !regexNo.test(this.expenseObj.amount)) {
      this.toastService.error("Please Enter A Valid Amount")
      return false
    }
    if (!this.expenseObj.amount) {
      this.toastService.error("Please Enter Amount ")
      return false
    }
    if (!this.expenseObj.expenseBy) {
      this.toastService.error("Please Select Team Member")
      return false
    }
    if (!this.expenseObj.paymentMode) {
      this.toastService.error("Please Select Payment Mode")
      return false
    }
    return true
  }
}