import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  {
    path: '', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
  },
  {
    path: 'catalogue', loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule)
  },
  {
    path: 'calendar', loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'reviews', loadChildren: () => import('./reviews/reviews.module').then(m => m.ReviewsModule)
  },
  {
    path: 'expenses', loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule)
  },
  {
    path: 'pricing', loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule)
  }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
