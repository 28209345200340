import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  private userService = environment.userService + '/v1';
  private zalonUserService = environment.zalonUserService + '/v1';
  private noAuth = new HttpHeaders().set('noauth', 'true');
  private getAuthHeaders(): HttpHeaders {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    let headers = new HttpHeaders();
    
    if (token) {
      headers = headers.set('Authorization', `Bearer ${JSON.parse(token)}`);
    }
    
    if (userId) {
      headers = headers.set('user-id', userId);
    }
    
    return headers;
  }

  getTeamMember(payload: any, queryObj: any): Observable<any> {
    return this.http
      .post<any>(
        this.userService +
        `/team/get?page=${queryObj.page}&pageSize=${queryObj.pageSize}`,
        payload
      )
      .pipe(catchError((err) => throwError(err)));
  }
  getTeamMemberById(id: any): Observable<any> {
    return this.http
      .get<any>(this.userService + `/team/get/${id}`)
      .pipe(catchError((err) => throwError(err)));
  }
  createTeamMember(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + '/team', payload)
      .pipe(catchError((err) => throwError(err)));
  }
  updateTeamMember(payload: any): Observable<any> {
    return this.http
      .put<any>(this.userService + `/team`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  sendOtpTeamMember(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/team/send-otp`, payload, {
        headers: this.noAuth,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  verifyOtpTeamMember(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/team/verify-otp`, payload, {
        headers: this.noAuth,
      })
      .pipe(catchError((err) => throwError(err)));
  }
  checkTeamMemberExist(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/team/check-email`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  checkTeamMemberExistByPhone(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/team/check-mobile`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  getOfferList(payload: any, page: any, pageSize: any): Observable<any> {
    return this.http
      .post<any>(
        this.userService + `/team/get?page=${page}&pageSize=${pageSize}`,
        payload
      )
      .pipe(catchError((err) => throwError(err)));
  }

  deleteTeamMemberMember(id: any, shopId: any): Observable<any> {
    return this.http
      .delete<any>(this.userService + `/team/${id}?shop_id=${shopId}`)
      .pipe(catchError((err) => throwError(err)));
  }

  sendOtp(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/user/send-otp`, payload, {
        headers: this.noAuth,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  verifyOtp(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/user/verify-otp`, payload, {
        headers: this.noAuth,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  //busniess details

  getBusinessDetailsById(id: any): Observable<any> {
    return this.http.get(
      this.zalonUserService + `/shop/fetch-shop/6455e709e6a12de0c2ba6902`
    );
  }

  updateBusinessDetails(id: any, payload: any): Observable<any> {
    return this.http
      .put(this.zalonUserService + `/shop/update-shop?id=${id}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  //user

  getUserById(id: any): Observable<any> {
    return this.http
      .get(this.zalonUserService + `/user/fetch/${id}`)
      .pipe(catchError((err) => throwError(err)));
  }

  updateUser(payload: any): Observable<any> {
    return this.http
      .post(this.userService + `/user/update`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  checkUserExist(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/user/check-email`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  addUser(reqObj: any): Observable<any> {
    return this.http
      .post<any>(this.userService + '/user/register', reqObj)
      .pipe(catchError((err) => throwError(err)));
  }

  editUser(reqObj: any): Observable<any> {
    return this.http
      .post<any>(this.userService + '/user/update', reqObj)
      .pipe(catchError((err) => throwError(err)));
  }

  // shop

  createShop(payload: any): Observable<any> {
    return this.http
      .post<any>(this.zalonUserService + `/shop/add-shop-unauth`, payload, {
        headers: this.noAuth
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getShopById(id: string): Observable<any> {
    return this.http
      .get<any>(this.zalonUserService + `/shop/fetch-shop/${id}`)
      .pipe(catchError((err) => throwError(err)));
  }

  updateShop(payload: any): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http
      .put<any>(
        `${this.zalonUserService}/shop/update-shop?id=${payload._id}`,
        payload,
        { headers }
      )
      .pipe(catchError((err) => throwError(err)));
  }
  checkShopNameExist(payload: any): Observable<any> {
    return this.http
      .post<any>(this.zalonUserService + `/shop/check-userName-exists`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  //role
  getModuleByRole(payload: any, page: any, pageSize: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/role/get?page=${page}&pageSize=${pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }
  getRoleById(id: any): Observable<any> {
    return this.http
      .get<any>(this.userService + `/role/${id}`)
      .pipe(catchError((err) => throwError(err)));
  }

  getShopTeamMemberRole(payload: any, page: any, pageSize: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/roleMap/get?page=${page}&pageSize=${pageSize}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  getShopListByIds(payload: any): Observable<any> {
    return this.http
      .post<any>(this.zalonUserService + `/shop/get-shop-list`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  // sms otp

  sendSMSOtp(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/team/send-sms-otp`, payload, {
        headers: this.noAuth,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  verifySMSOtp(payload: any): Observable<any> {
    return this.http
      .post<any>(this.userService + `/team/verify-sms-otp`, payload, {
        headers: this.noAuth,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  //membership
  getMembershipStatus(id: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http
      .get<any>(this.zalonUserService + `/shop/membership/status/${id}`, { headers })
      .pipe(catchError((err) => throwError(err)));
  }
  
  activateMembership(payload: any): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http
      .post<any>(this.zalonUserService + '/shop/membership/startTrial', payload, { headers })
      .pipe(catchError((err) => throwError(err)));
  }



}
