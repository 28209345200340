import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeconvertor'
})
export class TimeConvertorPipe implements PipeTransform {

  transform(value: any): any {
    const sec = parseInt(value, 10);
    let hours   = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - (hours * 3600)) / 60);
    // if (hours   < 10) {hours   = "0"+hours;}
    // if (minutes < 10) {minutes = "0"+minutes;}
    // if (seconds < 10) {seconds = "0"+seconds;}
    return (hours && minutes) ? hours+'h '+minutes+'min ': hours ? hours+'h' : minutes ? minutes+'min' : '0'
  }
}