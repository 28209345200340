import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonCommunicationService } from 'src/app/core/services/commonCommunication.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {
  activeShopId: string;
  shopDetails: any;
  private searchSubject = new Subject<string>();
  searchTerm: string = '';
  sortColumn: string = 'expenseType';
  sortDirection: 'asc' | 'desc' = 'asc';
  page: number = 1;
  pageSize: number = 10;
  expenseList: Array<any> = [];
  totalItems: number = 0;

  constructor(
    private router: Router,
    private ccs: CommonCommunicationService,
    private productService: ProductService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.ccs.getActiveShopId().subscribe(res => {
      if (res) {
        this.activeShopId = res;
        this.getAllExpenses();
        this.getShopDetails();
        this.setupSearchSubscription();
      }
    });
  }

  setupSearchSubscription() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      this.page = 1;
      this.getAllExpenses();
    });
  }

  getShopDetails() {
    this.userService.getShopById(this.activeShopId).subscribe(res => {
      this.shopDetails = res.result;
    });
  }

  getAllExpenses() {
    const payload = {
      shop_id: this.activeShopId,
      search_text: this.searchTerm
    };
    const queryObj = {
      page: this.page,
      pageSize: this.pageSize
    };

    this.productService.getAllExpenses(payload, queryObj).subscribe({
      next: (res: { data: any, totalRecords: number }) => {
        this.expenseList = res.data.map((item: any) => item);
        this.totalItems = res.totalRecords;
      },
      error: (err) => {
        console.error('Error fetching expenses:', err);
      }
    });
  }

  deleteExpenses(id: string) {
    this.productService.deleteExpenses(id).subscribe(() => {
      this.getAllExpenses();
    });
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    
    this.expenseList.sort((a, b) => {
      const valueA = a[column].toString().charAt(0).toUpperCase() + a[column].toString().slice(1).toLowerCase();
      const valueB = b[column].toString().charAt(0).toUpperCase() + b[column].toString().slice(1).toLowerCase();
  
      if (valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  navigate(type: string, expense?: any): void {
    if (type === 'ADD') {
      this.router.navigateByUrl('expenses/add-expenses');
    } else if (type === 'EDIT' && expense) {
      this.router.navigate(['expenses/edit-expenses'], {
        queryParams: {
          _id: expense._id
        }
      });
    }
  }

  onPageChange(event: number): void {
    this.page = event;
    this.getAllExpenses();
  }

  onSearchChange() {
    this.page = 1;
    this.searchSubject.next(this.searchTerm);
  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe();
  }
}