import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonCommunicationService } from 'src/app/core/services/commonCommunication.service';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
// import { ModuleList } from 'src/app/constants/application.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private ccs: CommonCommunicationService,
    private router: Router,
    private userService: UserService,
    private integrationService: IntegrationService,

  ) { }

  userDetails: any = {};
  nameCharacter: any = '';
  showDropDown: boolean = false;
  selectedShop: any;
  public shopList: any = [];
  public showNotifications = false;
  public notificationList: any = [];
  public activeNotificationsTab = 'all';
  public unreadCount: any = 0;
  totalNotifications: any = 0;
  showAnnouncements: boolean = false;
  announcementList: any = []
  totalItems: number = 0
  pageSize: number = 10
  page: number = 1

  // public allModuleList: any = ModuleList;

  ngOnInit() {
    this.ccs.getUserData().subscribe((res) => {
      if (res) {
        this.getAnnouncementsByCriteria()
        this.userDetails = res;
        if (this.userDetails.name && this.userDetails.name.length) {
          this.nameCharacter = this.userDetails.name[0];
        }
      }

      this.notificationBarState();
    });

    this.ccs.getActiveShopId().subscribe((res) => {
      if (res) {
        this.selectedShop = res;
        this.getNotification()
        this.ccs.getUserData().subscribe((res) => {
          if (res) {
            this.userDetails = res;

            // this.getModuleList();
            if (this.userDetails.shop_id && this.userDetails.shop_id.length) {
              this.getAllShopListTeamMember()
            }
          }
        });
      }
    });
  }

  shopIdsList: any = []
  getAllShopListTeamMember() {
    this.userService.getTeamMember({ mobile: this.userDetails.mobile_number }, { page: 1, pageSize: 100 }).subscribe(res => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i] && res.data[i].shop_id && res.data[i].shop_id.length) {
          if(!res.data[i].is_deactivated) {
            this.shopIdsList.push(res.data[i].shop_id[0])
          }
        }
      }
      this.getShopList();
    })
  }

  toggleAnnouncements() {
    this.getAnnouncementsByCriteria()
    this.showAnnouncements = !this.showAnnouncements
  }


  getAnnouncementsByCriteria() {
    const payload = {
      "shop_id": "",
      "announcement_type": "ALL"
    }

    this.integrationService.getAnnouncementsByCriteria(payload, this.page, this.pageSize).subscribe((res) => {
      this.announcementList = res.data
    })
  }


  toggleNotifications(e: any) {
    e.stopPropagation();
    this.showNotifications = !this.showNotifications;
    if (this.showNotifications) {
      this.ccs.setNotificationBarState('OPEN');
    } else {
      this.ccs.setNotificationBarState('CLOSE');
    }
  }

  updateNotificationStatus(item: any) {
    let payload = {
      "notificationId": item._id
    }
    this.integrationService.updateNotificationStatus(payload).subscribe(res => {
      this.getNotification()
      if (item.notification.dataBody.resource === 'ORDER') {
        this.router.navigateByUrl(`/sales/sales-details?orderId=${item.notification.dataBody.resourceId}`)
      }
    })
  }

  getNotification() {
    this.integrationService.getNotification(this.selectedShop, 0, 10).subscribe(res => {
      if (res && res.data && res.data.length) {
        this.notificationList = res.data
        this.totalNotifications = res.total
        let unreadNotification = this.notificationList.filter((item: any) => !item.isOpened)
        this.unreadCount = unreadNotification.length
      }
    })
  }

  getShopList() {
    let reqObj = {
      shopIds: this.shopIdsList,
    };
    this.userService.getShopListByIds(reqObj).subscribe((res: any) => {
      this.shopList = res.result;
      this.setShopData(this.selectedShop)
      // this.shopList = res.result.filter((item: any) => this.userDetails.shopId.includes(item._id))
    });
  }

  changeShop(shopId: any) {
    this.ccs.setActiveShopId(shopId);
    this.getShopTeamMember(shopId);
    this.setShopData(shopId)
  }

  getShopTeamMember(shopId: any) {
    this.userService.getTeamMember({ mobile: this.userDetails.mobile_number, shop_id: shopId }, { page: 1, pageSize: 100 }).subscribe(res => {
      console.log(res);
      if(res && res.data && res.data.length) {
        localStorage.setItem('userId', res.data[0]._id);
        this.getTeamShopRole(res.data[0])
      }
    })
  }

  getTeamShopRole(userData: any) {
    this.userService.getRoleById(userData.role_id).subscribe(result => {
      console.log(result.data.role_permission)
      this.ccs.setRolePermission(result.data.role_permission)
      localStorage.setItem('userDetails', JSON.stringify(userData));
      this.ccs.setUserData(userData)
      localStorage.setItem('activeShopId', userData.shop_id[0]);
    })
  }



  setShopData(id: any) {
    let shopDetails = this.shopList.find((item: any) => item._id == id)
    this.ccs.setShopDetails(shopDetails)
    localStorage.setItem('activeShopId', id);
  }

  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  navigateTo(type: any) {
    if (type == 'profile') {
      this.router.navigateByUrl('/profile');
    }
    if (type == 'add-store') {
      this.router.navigateByUrl('/auth/get-started');
    }
    if (type == 'preview-shop') {
      window.open(`${environment.previewShop}/${this.selectedShop}`)
    }
  }

  notificationBarState() {
    this.ccs.getNotificationBarState().subscribe((res) => {
      if (res) {
        if (res === 'OPEN') {
          this.showNotifications = true;
        } else if (res === 'CLOSE') {
          this.showNotifications = false;
        }
      }
    })
  }

  // userBarState() {
  //   this.ccs.getUserBarState().subscribe((res) => {
  //     if(res) {
  //       if(res === 'OPEN') {
  //         this.showUserDropdown = true;
  //       } else if(res === 'CLOSE') {
  //         this.showUserDropdown = false;
  //       }
  //     }
  //   })
  // }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  // public getModuleList() {
  //   let reqObj = {
  //     shop_id: this.selectedShop,
  //     role: this.userDetails.role,
  //   };
  //   this.userService.getModuleByRole(reqObj).subscribe((res: any) => {
  //     console.log(res.data[0], this.allModuleList)
  //     if (res && res.data && res.data.length) {

  //       let modules = res.data[0];
  //       let modulePermissionList = []
  //       this.ccs.setModuleList(modules.role_permission)
  //       for (let i = 0; i < this.allModuleList.length; i++) {

  //         if (this.allModuleList[i].subItems.length) {
  //           let submoduleItem = { ...this.allModuleList[i], subItems: [] }

  //           for (let j = 0; j < this.allModuleList[i].subItems.length; j++) {
  //             console.log(this.allModuleList[i].subItems[j].label, modules.role_permission[this.allModuleList[i].subItems[j].label]?.is_read)

  //             if (modules.role_permission[this.allModuleList[i].subItems[j].label].is_read) {
  //               console.log(this.allModuleList[i].subItems[j].label, modules.role_permission[this.allModuleList[i].subItems[j].label]?.is_read)
  //               submoduleItem.subItems.push(this.allModuleList[i].subItems[j])
  //             }
  //           }
  //           if (submoduleItem.subItems.length) {
  //             modulePermissionList.push(submoduleItem)
  //           }

  //         } else {
  //           if (modules.role_permission[this.allModuleList[i].label]?.is_read) {
  //             modulePermissionList.push(this.allModuleList[i])
  //           }
  //         }
  //       }
  //       console.log(modulePermissionList)
  //       this.moduleList = [...modulePermissionList]
  //     }
  //   });
  // }
}
