<div class="expense-wrapper">
  <div class="expense-container">
    <div class="expense-header">
      <h1 class="section-heading">Expenses</h1>
      <button class="btn-primary" (click)="navigate('ADD')">
        <p>Add Expense</p>
      </button>
    </div>
    <div class="search-bar-wrapper">
      <div class="search-bar-container">
        <input type="text" placeholder="Search by Expense type" class="input-search" 
               [(ngModel)]="searchTerm"(ngModelChange)="onSearchChange()">
      </div>
    </div>
    <div class="list-view">
      <table class="table-container">
        <thead>
          <tr>
            <th (click)="sortData('expenseType')" [class.asc]="sortColumn === 'expenseType' && sortDirection === 'asc'"
                [class.desc]="sortColumn === 'expenseType' && sortDirection === 'desc'">
              Expense type
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M7 15l5 5 5-5"/>
                <path d="M7 9l5-5 5 5"/>
              </svg>
            </th>
            <th (click)="sortData('amount')" [class.asc]="sortColumn === 'amount' && sortDirection === 'asc'"
                [class.desc]="sortColumn === 'amount' && sortDirection === 'desc'">
              Expense Amount
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M7 15l5 5 5-5"/>
                <path d="M7 9l5-5 5 5"/>
              </svg>
            </th>
            <th (click)="sortData('expenseBy')" [class.asc]="sortColumn === 'expenseBy' && sortDirection === 'asc'"
                [class.desc]="sortColumn === 'expenseBy' && sortDirection === 'desc'">
              Expense by
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M7 15l5 5 5-5"/>
                <path d="M7 9l5-5 5 5"/>
              </svg>
            </th>
            <th (click)="sortData('expenseNote')" [class.asc]="sortColumn === 'expenseNote' && sortDirection === 'asc'"
                [class.desc]="sortColumn === 'expenseNote' && sortDirection === 'desc'">
              Note
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M7 15l5 5 5-5"/>
                <path d="M7 9l5-5 5 5"/>
              </svg>
            </th>
            <th (click)="sortData('paymentMode')" [class.asc]="sortColumn === 'paymentMode' && sortDirection === 'asc'"
                [class.desc]="sortColumn === 'paymentMode' && sortDirection === 'desc'">
              Payment Mode
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M7 15l5 5 5-5"/>
                <path d="M7 9l5-5 5 5"/>
              </svg>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let expense of expenseList|paginate: { itemsPerPage: pageSize, currentPage: page, totalItems:totalItems }">
            <td>
              <div class="expense-type">
                <p>{{expense?.expenseType}}</p>
              </div>
            </td>
            <td>
              <div class="expense-amount">
                <p>{{expense?.amount}}</p>
              </div>
            </td>
            <td>
              <div class="expense-by">
                <p>{{expense?.expenseBy}}</p>
              </div>
            </td>
            <td>
              <div class="note">
                <p>{{expense?.expenseNote}}</p>
              </div>
            </td>
            <td>
              <div class="expense-by">
                <p>{{expense?.paymentMode}}</p>
              </div>
            </td>
            <td>
              <div class="action-icons">
                <div (click)="navigate('EDIT', expense)">
                  <img src="../../../../../../assets/icons/edit.svg" alt="Edit Icon">
                </div>
                <div (click)="deleteExpenses(expense._id)">
                  <img src="../../../../../../assets/icons/delete.svg" alt="Delete Icon">
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-container" *ngIf="expenseList.length>0">
        <pagination-controls class="pagination-controls" (pageChange)="onPageChange($event)"></pagination-controls>
      </div>
    </div>
  </div>
</div>