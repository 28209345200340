<div *ngIf="showTrialModal" class="modal-overlay">
  <div class="modal-container">
    <div class="icon-container">
      <img src="../../../../assets/icons/pricingmodal.png">
    </div>
  
    <h2>Unlock All Features Begin Using Now!</h2>
  
    <button class="trial-button" (click)="startFreeTrial()">
      Start 6 Months Free Trial
    </button>
    <br>
    <p [routerLink]="['/pricing']" class="pricing-info">Pricing After Free Trial</p>
  </div>
</div>
<div *ngIf="showCancelledModal" class="modal-overlay">
  <div class="modal-container">
    <div class="icon-container">
      <img src="../../../../assets/icons/canclled.png">
    </div>
  
    <h2>We're experiencing technical error :(</h2>
    <p>We're sorry for the inconvenience.</p>
    <br>
    <button class="contact-us-btn" (click)="reactivateMembership()">
      Contact Us
    </button>
    <br>
    <p class="cancelled-info">Or call us on +919871083330</p>
  </div>
</div>