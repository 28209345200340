import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommonCommunicationService {
  constructor(private http: HttpClient) { }
  moduleList$: BehaviorSubject<any> = new BehaviorSubject(null);
  userLoginData$: BehaviorSubject<any> = new BehaviorSubject(null);
  activeShopId$: BehaviorSubject<any> = new BehaviorSubject(null);
  authToken: any
  notificationBarState$: BehaviorSubject<any> = new BehaviorSubject(null);
  cartData: BehaviorSubject<any> = new BehaviorSubject(null);
  userBarState$: BehaviorSubject<any> = new BehaviorSubject(null);
  activeShopDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  rolePermission$: BehaviorSubject<any> = new BehaviorSubject(null);
  shopUserData$: BehaviorSubject<any> = new BehaviorSubject(null);
  loginFlow$: BehaviorSubject<any> = new BehaviorSubject(false);
  setRolePermission(acc: any) {
    this.rolePermission$.next(acc);
  }

  getRolePermission(): Observable<any>  {
    return this.rolePermission$.asObservable();
  }

  setModuleList(acc: boolean) {
    this.moduleList$.next(acc);
  }

  setAuthToken(acc: any) {
    this.authToken = acc
  }

  getAuthToken() {
    return this.authToken
  }

  setCartData(acc: any) {
    this.cartData.next(acc)
  }

  getCartData() {
    return this.cartData
  }

  getUserData(): Observable<any> {
    return this.userLoginData$.asObservable();
  }

  setUserData(data: any) {
    this.userLoginData$.next(data);
  }

  getActiveShopId(): Observable<any> {
    return this.activeShopId$.asObservable();
  }

  setActiveShopId(data: any) {
    this.activeShopId$.next(data);
  }

  getShopDetails(): Observable<any> {
    return this.activeShopDetails$.asObservable();
  }

  setShopDetails(data: any) {
    this.activeShopDetails$.next(data);
  }

  getNotificationBarState(): Observable<any> {
    return this.notificationBarState$.asObservable();
  }

  setNotificationBarState(data: any) {
    this.notificationBarState$.next(data);
  }


  getUserBarState(): Observable<any> {
    return this.userBarState$.asObservable();
  }

  setUserBarState(data: any) {
    this.userBarState$.next(data);
  }

  getShopUserData(): Observable<any> {
    return this.shopUserData$.asObservable();
  }

  setShopUserData(data: any) {
    this.shopUserData$.next(data);
  }

  getLoginFlow(): Observable<any> {
    return this.loginFlow$.asObservable();
  }

  setLoginFlow(data: any) {
    this.loginFlow$.next(data);
  }



}
