export const SidebarItems = [
    {
      title: "Dashboard",
      icon: "../../../../assets/icons/home.svg",
      path: ''
    },
    {
      title: "Calendar",
      icon: "../../../../assets/icons/calendar.svg",
      path: 'calendar'
    },
    {
        title: "Catalogue",
        icon: "../../../../assets/icons/catalogue.svg",
        submodule: true,
        module: [
          {
            title: 'Services',
            path: '/catalogue/service'
          },
          {
            title: 'Vouchers',
            path: '/catalogue/voucher'
          },
          {
            title: 'Memberships',
            path: '/catalogue/membership'
          },
          {
            title: 'Products',
            path: '/catalogue/product'
          },
          {
            title: 'Inventory',
            path: '/catalogue/inventory'
          }
        ]
    },
    {
        title: "Client",
        icon: "../../../../assets/icons/client.svg",
        path: '/client'
    },
    {
      title: "Sales",
      icon: "../../../../assets/icons/sales.svg",
      submodule: true,
      module: [
        {
          title: 'Sales',
          path: 'sales/list'
        },
        {
          title: 'New Sale',
          path: 'sales/new-sale'
        },
        {
          title: 'Transactions',
          path: 'sales/transaction'
        },
        {
          title: 'Vouchers Sold',
          path: 'sales/voucher-sold'
        },
        {
          title: 'Memberships Sold',
          path: 'sales/membership-sold'
        }
      ]
    },
    {
        title: "Settings",
        icon: "../../../../assets/icons/settings.svg",
        path: '/settings'
    },
    {
      title: "Reports",
      icon: "../../../../assets/icons/reports.svg",
      path: '/report'
    },
     {
      title: "Reviews",
      icon: "../../../../assets/icons/message-heart-square.svg",
      path: '/reviews'
    },
    {
      title: "Expenses",
      icon: "../../../../assets/icons/message-heart-square.svg",
      path: '/expenses'
    },
    {
      title: "Pricing",
      icon: "../../../../assets/icons/pricing.svg",
      path: '/pricing'
    }
  ]