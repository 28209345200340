import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonCommunicationService } from '../services/commonCommunication.service';
declare global {
  namespace HttpHeaders {
    interface headers {
      auth?: any;
    }
  }
}
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private ccs: CommonCommunicationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.has('noauth')) {
      let token = this.ccs.getAuthToken()
      if (token) {
        request = request.clone({
          setHeaders: { 'x-access-token': `${token}` },
        });
      }
    }
    console.log('inside auth interceptor');

    return next.handle(request);
  }
}
