import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { CommonCommunicationService } from 'src/app/core/services/commonCommunication.service';
import { UserService } from 'src/app/core/services/user.service';
import { PricingComponent } from 'src/app/pages/pricing/components/pricing-page/pricing.component';

@Component({
  selector: 'app-pricing-modal',
  templateUrl: './pricing-modal.component.html',
  styleUrls: ['./pricing-modal.component.scss']
})
export class PricingModalComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  public pricing: PricingComponent;
  public showTrialModal: boolean = false;
  public showCancelledModal: boolean = false;
  public membershipStatus: string = '';
  showModal: false;

  constructor(
    private userService: UserService,
    private ccs: CommonCommunicationService
  ) {}

  activeShopId: any;
  userDetails: any = {};

  ngOnInit() {
    this.ccs.getUserData().subscribe(res => {
      this.userDetails = res;
    });
    this.ccs.getActiveShopId().subscribe(res => {
      if (res) {
        this.activeShopId = res;
        this.checkMembershipStatus();
      }
    });
  }

  checkMembershipStatus() {
    const payload = this.activeShopId;
    this.userService.getMembershipStatus(payload).subscribe(
      (response: any) => {
        this.membershipStatus = response.pos_membership.membership_status;
        console.log('Membership status:', this.membershipStatus);
        this.showTrialModal = this.membershipStatus == "INACTIVE" ;
        this.showCancelledModal = this.membershipStatus === "CANCELLED";
        console.log('Show trial modal:', this.showTrialModal);
        console.log('Show cancelled modal:', this.showCancelledModal);
      },
      (error: any) => {
        console.error('Error fetching membership status:', error);
        // You might want to show an error message to the user
      }
    );
  }

  closeModal() {
    this.close.emit();
  }

  startFreeTrial() {
    const payload = { shopId: this.activeShopId };
    console.log('Payload for activating membership:', payload);
    this.userService.activateMembership(payload).subscribe(
      (response: any) => {
        console.log('Free trial started successfully:', response);
        this.membershipStatus = 'TRIAL';
        this.showTrialModal = false;
        this.showCancelledModal = false;
        this.closeModal();
      },
      (error: any) => {
        console.error('Error starting free trial:', error);
        // You might want to show an error message to the user
      }
    );
  }

  reactivateMembership() {
    const phoneNumber = '919871083330';
    window.location.href = `tel:${phoneNumber}`;
    console.log(`Initiating call to ${phoneNumber} for membership reactivation`);
  }
}