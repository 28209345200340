import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from './core/services/commonCommunication.service';
import { UserService } from './core/services/user.service';
import { Router,NavigationEnd,Event } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'Zalon Partner';
  userId: any
  activeShopId: any
  userData: any
  showModal: boolean = false;

  constructor(
    private ccs: CommonCommunicationService,
    private userService: UserService,
    private router:Router
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const excludedRoutes = ['/pricing', '/sign-in', '/get-started', '/mobile-verification'];
      this.showModal = !excludedRoutes.some(route => event.urlAfterRedirects.includes(route));
    });
    let clear = localStorage.getItem('clear4')
    console.log(clear)
    if (!clear) {
      localStorage.clear()
      localStorage.setItem('clear4', 'true')
      location.reload()
    }
    let token: any = JSON.parse(localStorage.getItem('token') || '{}')
    this.ccs.setAuthToken(token)
    this.userId = localStorage.getItem('userId')

    if (this.userId) {
      this.userService.getTeamMemberById(this.userId).subscribe((res: any) => {
        if (res) {
          console.log(res)
          this.userData = res
          this.ccs.setUserData(res)
          this.activeShopId = localStorage.getItem('activeShopId')
          // this.activeShopId = res.shop_id[0]
          this.ccs.setActiveShopId(this.activeShopId)
          this.getTeamShopRole()
        } else {
          this.userService.getUserById(this.userId).subscribe(res => {
            if (res && res.data) {
              res.data.shop_id = res.data.shopId
              this.ccs.setUserData(res.data)
              this.activeShopId = res.data.shopId[0]
              this.ccs.setActiveShopId(this.activeShopId)
            }

          })
        }

      })
    }
  }
  getTeamShopRole() {
    this.userService.getRoleById(this.userData.role_id).subscribe(result => {
      console.log(result.data.role_permission)
      this.ccs.setRolePermission(result.data.role_permission)
    })
  }

}