<div class="sidebar-wrapper">
    <div class="sidebar-nav">
        <ul>
            <li *ngIf="lastPageUrl !== ''" (click)="setActiveMenuItem()"><a [routerLink]="['']" 
                    [class.active-link]="lastPageUrl === ''">
                    <img src="../../../../assets/icons/home.svg" alt="Dashboard">
                    <span class="text">Dashboard</span>
                </a>
            </li>
            <li *ngIf="lastPageUrl === ''" (click)="setActiveMenuItem()"><a [routerLink]="['']" routerLinkActive="active-link"
                [class.active-link]="lastPageUrl === ''">
                <img src="../../../../assets/icons/home.svg" alt="Dashboard">
                <span>Dashboard</span>
            </a>
        </li>
            <li (click)="setActiveMenuItem()"><a [routerLink]="['calendar']" routerLinkActive="active-link"
                    [class.active-link]="lastPageUrl === 'calendar'">
                    <img src="../../../../assets/icons/calendar.svg" alt="Calendar">
                    <span class="text">Calendar</span>
                </a>
            </li>

            <li (click)="setActiveMenuItem()"><a [class.active-link]="(lastPageUrl === 'service') || (lastPageUrl === 'voucher') || (lastPageUrl === 'membership') ||
            (lastPageUrl === 'supplier') || (lastPageUrl === 'product')"
                    [routerLink]="['/catalogue/service']">
                    <img src=".../../../../assets/icons/catalogue.svg" alt="Catalogue"><span class="text">Catalogue</span>
                </a></li>

            <li (click)="setActiveMenuItem()" *ngIf="catalougeOpenFlag == true">
                <a [routerLink]="['/catalogue/service']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-peace" viewBox="0 0 16 16">
                        <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793zm1 0v6.775l4.79 4.79A7 7 0 0 0 8.5 1.018m4.084 12.273L8.5 9.207v5.775a6.97 6.97 0 0 0 4.084-1.691M7.5 14.982V9.207l-4.084 4.084A6.97 6.97 0 0 0 7.5 14.982M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8"/>
                        </svg>
                        <span class="text">Services</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="catalougeOpenFlag == true">
                <a [routerLink]="['/catalogue/voucher']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-postcard" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0zM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5M10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM13 8h-2V6h2z"/>
                        </svg>
                        <span class="text">Vouchers</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="catalougeOpenFlag == true">
                <a [routerLink]="['/catalogue/membership']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                        </svg>
                       <span class="text">Memberships</span> 
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="catalougeOpenFlag == true">
                <a [routerLink]="['/catalogue/product']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-boxes" viewBox="0 0 16 16">
                        <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434zM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567zM7.5 9.933l-2.75 1.571v3.134l2.75-1.571zm1 3.134 2.75 1.571v-3.134L8.5 9.933zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567zm2.242-2.433V3.504L8.5 5.076V8.21zM7.5 8.21V5.076L4.75 3.504v3.134zM5.258 2.643 8 4.21l2.742-1.567L8 1.076zM15 9.933l-2.75 1.571v3.134L15 13.067zM3.75 14.638v-3.134L1 9.933v3.134z"/>
                        </svg>
                        <span class="text">Products</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()"><a [routerLink]="['client']" routerLinkActive="active-link"
                    [class.active-link]="lastPageUrl === 'client'">
                    <img src="../../../../assets/icons/client.svg" alt="Client">
                    <span class="text">Client</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()"><a [routerLink]="['/catalogue/inventory']"
                routerLinkActive="active-link" [class.active-link]="lastPageUrl === 'inventory'">
                <img src="../../../../assets/icons/checklists.svg" alt="Inventory" class="svg-icon">
                <span class="text">Inventory</span>
                </a>
            </li>

            <li (click)="setActiveMenuItem()"><a [class.active-link]="(lastPageUrl === 'list') || (lastPageUrl === 'new-sale') || (lastPageUrl === 'transaction') 
            ||(lastPageUrl === 'voucher-sold') || (lastPageUrl === 'membership-sold')"
                    [routerLink]="['/sales/new-sale']">
                    <img src="../../../../assets/icons/sales.svg" alt="Sales">
                    <span class="text">Sales</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="salesOpenFlag == true">
                <a [routerLink]="['/sales/new-sale']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>
                        <span class="text">New Sale</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="salesOpenFlag == true">
                <a [routerLink]="['/sales/list']" routerLinkActive="active-link" style="font-size: 12px;"
                    [class.active-link]="lastPageUrl === 'list'">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-data" viewBox="0 0 16 16">
                        <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0z"/>
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                        </svg>
                    <span class="text">Sales</span>
                </a>
            </li>
            
            <li (click)="setActiveMenuItem()" *ngIf="salesOpenFlag == true">
                <a [routerLink]="['/sales/transaction']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                        </svg>
                        <span class="text">Transactions</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="salesOpenFlag == true">
                <a [routerLink]="['/sales/voucher-sold']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-postcard" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0zM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5M10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM13 8h-2V6h2z"/>
                        </svg>
                        <span class="text">Vouchers Sold</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()" *ngIf="salesOpenFlag == true">
                <a [routerLink]="['sales/membership-sold']" routerLinkActive="active-link" style="font-size: 12px;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    </svg>
                    - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                        </svg>
                        <span class="text">Memberships Sold</span>
                </a>
            </li>
            <li (click)="setActiveMenuItem()"><a [routerLink]="['/expenses']"
                routerLinkActive="active-link" [class.active-link]="lastPageUrl === 'expenses'">
                <img src="../../../../assets/icons/Group.svg" alt="Inventory" class="svg-icon">
                <span class="text">Expenses</span>
            </a>
        </li>
            <li (click)="setActiveMenuItem()"><a [routerLink]="['reviews']" routerLinkActive="active-link"
                    [class.active-link]="lastPageUrl === 'reviews'">
                    <img src="../../../../assets/icons/message-heart-square.svg" alt="Reviews">
                    <span class="text">Reviews</span>
                </a>
            </li>
            
        <li (click)="setActiveMenuItem()"><a [routerLink]="['report']" routerLinkActive="active-link"
            [class.active-link]="lastPageUrl === 'report'">
            <img src="../../../../assets/icons/reports.svg" alt="Reports">
            <span class="text">Reports</span>
            </a>
        </li>
        <li (click)="setActiveMenuItem()"><a [routerLink]="['settings']" routerLinkActive="active-link"
            [class.active-link]="lastPageUrl === 'settings'">
            <img src="../../../../assets/icons/settings.svg" alt="Settings">
            <span class="text">Settings</span>
            </a>
        </li>
        <!-- <li (click)="setActiveMenuItem()"><a [routerLink]="['pricing']" routerLinkActive="active-link"
            [class.active-link]="lastPageUrl === 'pricing'">
            <img src="../../../../assets/icons/pricing.svg" alt="pricing">
            <span class="text">Pricing</span>
            </a>
        </li> -->
        </ul>
    </div>
</div>
