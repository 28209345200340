<header class="header-wrapper">
  <div class="logo">
    <div class="logo-image">
      <img src="../../../../assets/icons/zalonLogo.svg" alt="Zalon Logo" />
    </div>
  </div>
  <div class="right-container">
    <div class="input-group">
      <ng-select
        class="input-select"
        placeholder="Select shop"
        [(ngModel)]="selectedShop"
        (ngModelChange)="changeShop($event)"
        [clearable]="false"
      >
        <ng-option [value]="shop._id" *ngFor="let shop of shopList">{{
          shop.businessName
        }}</ng-option>
      </ng-select>
    </div>
    <div (click)="toggleNotifications($event)" class="notification-icon-container">
      <img src="../../../../assets/icons/web.svg" class="notifications-icon"/>
      <p class="notification-badge" *ngIf="!!unreadCount">{{unreadCount}}</p>

    </div>
    <div (click)="toggleAnnouncements()">
      <img src="../../../../assets/icons/announcement.svg" class="notificatios-icon"/>
    </div>

    <div class="profile-info" (click)="toggleDropDown()">
      <span>{{ nameCharacter }}</span>

      <div class="drop-down" *ngIf="showDropDown">
        <span (click)="navigateTo('profile')">My Profile</span>
        <span (click)="navigateTo('add-store')">Add Salon</span>
        <span (click)="navigateTo('preview-shop')">Preview Salon</span>
        <span (click)="logout()">Logout</span>
      </div>
    </div>

     <!---------------------------------------------Notifications------------------------------------ -->
     <div
     class="notification-wrapper"
     ngbDropdown
     *ngIf="showNotifications"
     (click)="$event.stopPropagation()"
   >
     <!-- <button
       type="button"
       class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
       id="page-header-notifications-dropdown"
       data-bs-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false"
       ngbDropdownToggle
     >
       <i class="bx bx-bell fs-22"></i>
       <span
         class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"
         ><span class="visually-hidden">unread messages</span></span
       >
     </button> -->
     <div class="notification-container">
       <div class="notifications-header">
         <div class="notifications-heading">
           <p>Notifications</p>
           <p class="new-notifications">{{unreadCount}} New</p>
         </div>
         <div class="notifications-tabs">
           <p
             [ngClass]="
               activeNotificationsTab === 'all' ? 'active-tab' : 'tab'
             "
             (click)="activeNotificationsTab = 'all'"
           >
             All({{totalNotifications}})
           </p>

           <p
             [ngClass]="
               activeNotificationsTab === 'alerts' ? 'active-tab' : 'tab'
             "
             (click)="activeNotificationsTab = 'alerts'"
           >
             Alerts
           </p>
         </div>
       </div>

       <div class="notifications-list" *ngIf="activeNotificationsTab==='all'">
         <ng-container *ngIf="notificationList.length">
             <div class="notification-card" *ngFor="let item of notificationList" (click)="updateNotificationStatus(item)">
               <div class="dot-icon"></div>

               <div>
                 <p>
                     {{item.notification.body}}
                 </p>
                 <p class="notification-card-date">{{item.createdAt |  date : "medium"}}</p>
               </div>
             </div>
         </ng-container>
         <ng-container *ngIf="!notificationList.length">
             <div class="notifications-alerts">
                 <img src="../../../assets/icons/bell.svg" class="alert-icon"/>
                 <p>Hey! You have no any notifications</p>
             </div>
         </ng-container>
       </div>

       <div class="notifications-alerts" *ngIf="activeNotificationsTab==='alerts'">
           
         <img src="../../../assets/icons/bell.svg" class="alert-icon"/>
         <p>Hey! You have no any notifications</p>
       </div>
     </div>
   </div>
  </div>

    <!-- <div class="more-filters-wrapper" *ngIf="showAnnouncements">
      <div class="more-filters-container">
        <div class="more-filters-header">
          <p class="filters-heading">Announcements</p>
          <img src="../../../../../assets/icons/close.svg" alt="icon" (click)="toggleAnnouncements()"K/>
        </div>

        <div class="form-group">
           <div class="announcements-list">
             <div class="announcement-card">
                <img src='../../../../assets/images/logo-full.png' width="50px" height="40px" class="announcement-img"/>
                <div class="announcemet-info">
                  <p class="announcement-heading">1st Announcement</p>
                  <p class="announcement-description">Announcement description</p>
                </div>
             </div>
           </div>
        </div>
    </div>
  </div>
          -->
</header>
