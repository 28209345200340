<div class="add-expenses-wrapper">
  <div class="add-expenses-container">
    <div class="header">
      <button (click)="createExpenseInfo()" class="save-btn"*ngIf="!editMode">Add Expense</button>
      <button class="save-btn" (click)="updateExpenses()" *ngIf="editMode">Update</button>
      <p class="add-expenses-heading">{{ editMode ? 'Edit' : 'Add' }} Expenses</p>
    </div>
    <div class="form-container">
      <div class="form-group">
        <p class="form-heading">Expense Details</p>
        <div class="flex-inputs">
          <div class="input-group">
            <label class="input-label" for="expenseType">Expense Type</label>
            <ng-select
              id="expenseType"
              [(ngModel)]="expenseObj.expenseType"
              [items]="expenseTypes"
              [addTag]="addNewExpenseType.bind(this)"
              [hideSelected]="true"
              placeholder="Choose or add new Expense Type"
              required
            >
            </ng-select>
          </div>
          <div class="input-group">
            <label class="input-label" for="expenseAmount">Expense Amount:</label>
            <input id="expenseAmount" [(ngModel)]="expenseObj.amount" type="number" class="input-box" placeholder="Enter Expense Amount" min="1" required />
          </div>
        </div>
        <div class="flex-inputs">
          <div class="input-group">
            <label class="input-label" for="expenseBy">Expense By:</label>
            <ng-select
              id="expenseBy"
              [(ngModel)]="expenseObj.expenseBy"
              name="expenseBy"
              placeholder="Select Team Member"
              required
            >
              <ng-option *ngFor="let member of teamMemberList" [value]="member.name">{{ member.name }}</ng-option>
            </ng-select>
          </div>
          <div class="input-group">
            <label class="input-label" for="paymentMode">Payment Mode:</label>
            <ng-select
              id="paymentMode"
              [(ngModel)]="expenseObj.paymentMode"
              name="paymentMode"
              placeholder="Select Payment Mode"
            >
              <ng-option *ngFor="let mode of paymentModes" [value]="mode">{{ mode }}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="flex-inputs">
        </div>
      </div>
      <div class="form-group">
        <div class="form-heading">
          <p>Important Note</p>
          <p class="form-subheading">
            Important Expense info will only be visible to you and team
            members.
          </p>
        </div>
        <div class="expenses-important-info">
          <label class="input-label">Expense Info</label>
          <textarea class="input-text-area" type="text" rows="5" placeholder="e.g. Last Months Electricity Bill"
            [(ngModel)]="expenseObj.expenseNote"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>